.Popcorn-Game {
    font-family: 'Helvetica', 'Arial', sans-serif;
    text-align: center;
    height: auto;
    z-index: 0;
    background-color: #ffffff !important;
  }
  
  .popcorn-game-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('./popcorn-game-assets/images/game_background.jpg');
    background-position: 50% 70%;
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 1000;
    overflow: hidden;
  }
  
  .game-description {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  
  .game-popcorn {
    width: 26.8vw;
    height: 26.8vw;
    cursor: pointer;
  }

  @media only screen and (min-width: 760px) {
    .game-popcorn {
      width: 20.8vw;
      height: 20.8vw;
    }
  }

  @media only screen and (min-width: 961px) {
    .game-popcorn {
      width: 10.4vw;
      height: 10.4vw;
    }
  }
  
  .game-score-image {
    position: relative;
    background: url('./popcorn-game-assets/images/score.svg');
    background-repeat: no-repeat;
    width: 192px;
    height: 192px;
    text-align: center;
    color: #000000;
    z-index: 1000;
  }
  
  .game-score-number {
    font-size: 34px;
    font-weight: bold;
    color: #D32328;
    text-align: center;
    padding: 94px 5px 0 0;
  }
  
  .game-component {
    position: absolute;
    z-index: -3;
    overflow: hidden;
  }
  
  .word-popcorn-container {
    font-weight: 900; 
    position: absolute;
    /* top: 77.5px; */
    bottom: 0;
    /* left: 1030px; */
    cursor: pointer;
    font-family: 'Helvetica', 'Arial', sans-serif;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: white;
  }

  .word-popcorn {
    font-family: 'Helvetica', 'Arial', sans-serif;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: white;
    font-weight: bolder; 
    font-size: 5.4vw;
  }

  @media only screen and (min-width: 760px) {
    .word-popcorn {
      font-size: 3.6vw; 
    }
  }

  @media only screen and (min-width: 961px) {
    .word-popcorn {
      font-size: 1.9vw; 
    }
  }
  
  .flex-c {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .game-title-logo {
    min-width: 120px;
    width: 100%;
    height: auto;
  }

  @media only screen and (min-width: 760px) {
    .game-title-logo {
      max-width: 36vw;
    }
  }

  @media only screen and (min-width: 961px) {
    .game-title-logo {
      max-width: 18vw;
    }
  }
  
  .game-title {
    font-size: 28px;
    padding: 0 24px;
  }
  
  .game-title-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .instruction-container {
    display: flex;
    align-items: center;
  }
  
  .timer {
    font-size: 32px;
    font-weight: bolder;
    position: relative;
    background-color: #D32328;
    width: 100px;
    height: 50px;
    border-radius: 15px;
    color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 40px;
    margin-bottom: 60px;
    z-index: 1000;
  }
  
  .timer-text {
    padding-top: 1.5px;
  }
  
  .top-game-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .btn-start-game {
    font-family: 'Helvetica', 'Arial', sans-serif;
    display: inline-block;
    max-width: 100%;
    font-weight: 900;
    color: #b21818;
    background-color: #FBB119;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid transparent;
    padding: 0.25rem 1.0rem;
    text-decoration: none;
    cursor: pointer;
  }

  @media only screen and (min-width: 760px) {
    .btn-start-game {
      font-size: 4.16vw;
    }
  }

  @media only screen and (min-width: 961px) {
    .btn-start-game {
      font-size: 2.08vw;
    }
  }
  
  .instruction-text {
    color: #FBB119;
    text-decoration: underline;
    font-weight: 500;
  }
  
  
  .btn-start-game:hover {
    background-color: #FBB119;
    text-decoration: none;
    color: #b21818;
  }
  
  .total-number {
    font-size: 14.04vw;
    font-weight: bolder;
    margin: 10px 0;
    color: #b21818;
  }

  @media only screen and (min-width: 760px) {
    .total-number {
      font-size: 9.36vw;
    }
  }

  @media only screen and (min-width: 961px) {
    .total-number {
      font-size: 4.68vw;
    }
  }
  
  .total-score-title {
    font-size: 7.02vw;
    color: #fbb119;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
  }

  @media only screen and (min-width: 760px) {
    .total-score-title {
      font-size: 4.68vw;
    }
  }

  @media only screen and (min-width: 961px) {
    .total-score-title {
      font-size: 2.34vw;
    }
  }
  
  .game-over-modal {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-width: 550px;
    min-height: 700px;
    margin: auto;
  }
  
  .game-over-modal-content {
    position: relative;
    background-color: #ffffff;
    border: 6px solid #000000;
    text-align: center;
    overflow: hidden;
    border-radius: 25px;
    padding: 10px;
    min-height: 10vh;
    width: 75vw;
    height: auto;
    margin: auto;
    margin-top: 0.5%;
  }

  @media only screen and (min-width: 760px) {
    .game-over-modal-content {
      width: 50vw;
      height: auto;
    }
  }

  @media only screen and (min-width: 961px) {
    .game-over-modal-content {
      width: 25vw;
      height: auto;
    }
  }
  
  .answers-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 35px;
  }

  @media only screen and (min-width: 760px) {
    .answer-row {
      font-size: 3.2vw;
    }
  }

  @media only screen and (min-width: 961px) {
    .answer-row {
      font-size: 1.6vw;
    }
  }
  
  .btn-container {
    display: flex;
    justify-content: center;
    margin: 5px 0 0px 0;
  }