.div_card {
    max-width: 136px; 
    min-width: 130px;
    max-height: 215px;
    min-height: 135px;
    margin: 0px 0px;
    cursor: pointer;
}

.col-homepage-gb {
    position: relative;
    width: 156px;
    padding-left: 15px;
    padding-right: 15px;
}

.spn_number_small_text {
    font-size: 18px;
    font-weight: 800;
    color: #ffffff;
}

.card-no-decoration {
    text-decoration: none;
}

.card-no-decoration:hover {
    text-decoration: none;
}

.div_small_card {
    max-width: 135px;
    min-width: 101px;
    height: auto;
    margin: 0px 0px;
    cursor: pointer;
}

.div_bottom_card {
    flex: 1 1 auto;
}

.div_background_color {
    background-color: #F0F7EB;
    /* min-height: calc(32vh - 30px); */
    min-height: 185px;
    padding: 40px;
}

.div_grade_text {
    color: #FFFFFF;
    font-weight: 900;
    padding-bottom: 0rem !important;
}

.div_first_box_blue {
    background-color: #0E78C6;
}

.hover_first_box_blue {
    background-color: #004A8B;
}

.div_bottom_blue {
    background-color: #004A8B;
}

.hover_bottom_blue {
    background-color: #0E78C6;
}

.div_first_box_yellow {
    background-color: #F2DA64;
}

.hover_first_box_yellow {
    background-color: #BCA12E;
}

.div_bottom_yellow {
    background-color: #BCA12E;
}

.hover_bottom_yellow {
    background-color: #F2DA64;
}

.div_first_box_orange {
    background-color: #E98129;
}

.hover_first_box_orange {
    background-color: #A8591E;
}

.hover_bottom_orange {
    background-color: #E98129;
}

.div_bottom_orange {
    background-color: #A8591E;
}

.div_first_box_red {
    background-color: #DB473B;
}

.hover_first_box_red {
    background-color: #96302A;
}

.hover_bottom_red {
    background-color: #DB473B;
}

.div_bottom_red {
    background-color: #96302A;
}

.div_first_box_green {
    background-color: #AFD81B;
}

.hover_first_box_green {
    background-color: #468C45;
}

.hover_bottom_green {
    background-color: #AFD81B;
}

.div_bottom_green {
    background-color: #468C45;
}

.div_first_box_pink {
  background-color: #E61587;
}

.hover_first_box_pink {
  background-color: #941159;
}

.div_bottom_pink {
  background-color: #941159;
}

.hover_bottom_pink {
  background-color: #E61587;
}

.div_first_box_black {
  background-color: #7a7777;
}

.hover_first_box_black {
  background-color: #000000;
}

.div_bottom_black {
  background-color: #000000;
}

.hover_bottom_black {
  background-color: #7a7777;
}

.div_first_box_purple {
    background-color: #C18ED6;
}

.hover_first_box_purple {
    background-color: #9B56B2
}

.div_bottom_purple {
    background-color: #9B56B2;
}

.hover_bottom_purple {
    background-color: #C18ED6;
}

.div_first_box_skyblue {
    background-color: #6CC6E5;
}

.hover_first_box_skyblue {
    background-color: #2B91BF;
}

.div_bottom_skyblue {
    background-color: #2B91BF;
}

.hover_bottom_skyblue {
    background-color: #6CC6E5;
}

.spn_number {
    font-size: 84px;
    font-weight: 900;
    color: #FFFFFF;
}

.spn_number_second_text {
    font-size: 38px;
    font-weight: 900;
    color: #FFFFFF;
}

.spn_grade_text {
    font-size: 38px;
    font-weight: 900;
    color: #FFFFFF;
}