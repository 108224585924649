.answer-text {
  width: 300px;
}

.ice-cream-title {
  color: #d91572;
  font-size: 48px;
  text-shadow:
    -1px -1px 0 #fff,
    1px -1px 0 #fff,
    -1px 1px 0 #fff,
    1px 1px 0 #fff;
  animation: grow 2s linear;
}

.ice-cream-grow {
  animation: expand 1s linear;
}

.ice-cream-shrink {
  transform: scale(0);
  animation: shrink 2s forwards;
}

@keyframes expand {
  0% {
    transform: scale(0);
  }
  75% {
    transform: scale(1.2);
  }
  87.5% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes grow {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.5);
  }
  20% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.5);
  }
  40% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  60% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.5);
  }
  80% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shrink {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0)
  }
}

@keyframes bounce {
  20%, 50%, 80%, to {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  70% {
    transform: translateY(-15px);
  }
  90% {
    transform: translateY(-4px);
  }
}

.ice-cream-container {
  height: 540px;
  overflow: auto;
}

.ice-cream-container-2 {
  padding: 30px 20px;
}

.ice-cream-end-screen {
  padding: 20px;
}

.ice-cream-game-header {
  width: 500px;
  margin: 10px 0 240px 0;
}

.btn-play-game {
  font-family: 'Alegreya', serif;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  color: #ffffff;
  background-color: #db473b;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 2.5rem;
  margin: 0.5rem;
  padding: 0.25rem 3.0rem;
}

.ice-cream-score {
  background-color: #F2DA64;
  border: 1px solid transparent;
  margin: 10px auto;
  width: 250px;
  padding: 10px;
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: 700;
}

.back-ice-cream-btn {
  font-family: 'Alegreya', serif;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  background-color: #d91572;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border-radius: 1.0rem;
  border: 4px solid white;
}

.ready-ice-cream-btn {
  font-family: 'Alegreya', serif;
  display: inline-block;
  font-size: 30px;
  font-weight: 700;
  color: #ffffff;
  padding: 0px 10px;
  background-color: #d91572;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border-radius: 1.0rem;
  border: 4px solid white;
}

.ready-ice-cream-btn:hover {
  background-color: #f47523;
}

.refresh-ice-cream-btn {
  font-family: 'Alegreya', serif;
  display: inline-block;
  font-size: 50px;
  font-weight: 1000;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border-radius: 100%;
  background-color: #f47523;
  border: 4px solid white;
}

.refresh-ice-cream-btn:hover {
  background-color: #d91572;
}

.ice-cream-btn-arrow:hover {
  animation: rotate infinite 2s linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hint-container {
  background-color: #DB473B;
}

.assertion-container {
  background-color: #ffe995;
  color: #000000;
  padding: 5px;
  margin: 5px;
  border: 5px solid #ffffff;
}

.direction-ice-cream-header-text {
  color: #d91572;
}

.direction-ice-cream-text {
  color: #f47523;
}

.current-topping {
  border: 4px solid rgb(255, 233, 149);
}