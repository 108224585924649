.stat-circle {
  background-color: #F19962;
  text-align: center;
  width: 90px;
  padding: 5px 0;
  color: #000000;
  border-radius: 100%;
  font-size: 50px;
  font-weight: bolder;
}

.user-profile-header {
  color: #ffffff;
  font-weight: bolder;
}

.profile-avatar {
  margin-right: 15px;
}

.profile-menu {
  font-size: 32px;
  cursor: pointer;
}

.classroom-stat {
  background-color: #0E78C6;
}

.student-stat {
  background-color: #AFD81B;
}

.games-played-stat {
  background-color: #E98129;
}