.spn_help_text {
  color: #FFFFFF;
  font-size: 35px;
  font-weight: bolder;
  background-color: #F19962;
  width: 100%;
  height: 80px;
  padding: 10px;
}

.div_about_help_container_fluid_style {
  background-color: #C5D9B9;
  height: 73vh;
  padding-bottom: 60px;
}

.div_help_card_height_width {
  margin-top: 16px;
  border-radius: 1.25rem !important;
  -webkit-border-radius: 1.25rem !important;
  -moz-border-radius: 1.25rem !important;
  -ms-border-radius: 1.25rem !important;
  -o-border-radius: 1.25rem !important;
}

.h5_help_title_color {
    color: #F24406;
}