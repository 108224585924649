/*
.div_game_desc_card {
    background-color: #f0f7eb;
    min-width: 200px;
    max-height: 500px;
    min-height: 500px;
    margin: 40px 20px;
}

.div_game_desc_background {
    background-color: #f0f7eb;
    padding: 40px 40px 0 40px;
}

.div_game_desc_card {
    width: 816px;
    min-height: 60vh;
    border-radius: 2em! important;
    margin-top: 20px;
    margin-bottom: 20px;
    -webkit-border-radius: 2em! important;
    -moz-border-radius: 2em! important;
    -ms-border-radius: 2em! important;
    -o-border-radius: 2em! important;
}

.div_game_desc_body {
    margin-left: 10px;
}

.div_grade_desc_body {
    margin-top: -20px;
}

.div_game_grade_number {
    background: #000000;
    margin-top: -20px;
}

.div_game_grade_text {
    font-size: 30px;
    font-weight: bold;
    color: #ffffff;
    padding: 8px;
    text-align: center;
}

.game_title_text {
    margin-bottom: 0.75rem;
    font-size: 30px;
}

.game-subtitle-text {
    padding-bottom: 10px;
    margin-top: 0.375rem;
}
*/

.div_grade_box {
    min-height: 100px;
    background-color: #000000;
}

.div_game_page_container_fluid {
    background-color: #f0f7eb;
}

.div_game_styling {
    width: 816px;
    min-height: 33vh;
    border: 1px solid transparent !important;
    margin-top: 20px;
    margin-bottom: 20px;
}
.div_game_quarter_circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #a9a9a9;
    background-image: linear-gradient(90deg,#fff 50%,transparent 0),linear-gradient(180deg,#f19962 50%,transparent 0)
}

.div_game_card_body{
    margin-left: 10px;
}

.div_game_grade_number_div {
    padding-left: -32px !important;
    margin-top: -21px;
}

.div_col {
    flex: 0 0 25%;
    max-width: 25%;
    position: relative;
    width: 100%;
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: -29.5px;
}

.div_game_grade_number_styling {
    color: #fff;
    font-size: 35px;
    padding: 33.25px 25px 33.25px 25px ;
    text-align: center;
}

.h5_game_font_styling {
    font-size: 30px;
}

.card-title {
    margin-bottom: .75rem;
    font-family: 'Alegreya', serif;
}

.card-subtitle {
    margin-top: -.375rem;
}

.h6_direction_text {
    padding-bottom: 10px;
}

.div_game_how_to_play_row {
    margin-top: 30px;
}

.spn_game_text_styling {
    font-size: 18px;
    color: #f19962;
}

.p_pair_voc_spacing {
    margin-bottom: 0;
}

.spn_vocab_dot_styling {
    height: 5px;
    width: 5px;
    background-color: #000;
    border-radius: 50%;
    display: inline-block;
}

.spn_game_instruction_container {
    padding-bottom: 5px;
}

.game_card_description {
    white-space: pre-line;  
    vertical-align: bottom;
    line-height: 20px;
}

.div_game_play_time_circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #a9a9a9;
    background-image: linear-gradient(90deg,#fff 50%,transparent 0),linear-gradient(102deg,#f19962 50%,transparent 0)
}

.div_game_half_circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #a9a9a9;
    background-image: linear-gradient(90deg,#fff 50%,transparent 0),linear-gradient(270deg,#f19962 50%,transparent 0)
}

.div_technique_or_skill_used {
    padding-top: 20px;
}

.btn_game_play_styling {
    width: 192px;
    height: 57px;
    background-color: #f24406!important;
    border-radius: 2em !important;
}

.btn-play-game-container {
    margin-top: 30px;
}