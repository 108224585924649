.spin-btn {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: #ffffff;
  width: 80px;
  height: 80px;
  box-shadow: 0 0 0 5px #000000, 0 0 15px 5px rgba(0, 0, 0, 0.6);
}

.entry-circle {
  background-color: #d4d4d4;
  width: 48px;
  height: 48px;
  border-radius: 100%;
}

.customize-container {
  padding: 5px;
}

.spin-btn:after {
  content: "";
  position: absolute;
  top: -20px;
  border: 20px solid transparent;
  border-bottom-color: #000000;
  border-top: none;
}

.spinner-setting {
  text-decoration: underline;
}

.entry-border-bot {
  border-bottom: 1px solid #D2D2D2; 
}

.color-setting {
  writing-mode: vertical-lr;
  text-orientation: mixed;
  transform: rotate(180deg);

  font-size: 14px;
}

.winner-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: #fff;
  font-size: 20px;
  width: auto;
  height: auto;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 0 0 5px #000000, 0 0 15px 5px rgba(0, 0, 0, 0.6);
  
}

.input-entry-text {
  max-width: 200px;
  width: 150px;
  height: auto;
  background-color: #f5f5f5;
  padding: 5px;
  margin-right: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
}

.winner-text {
  font-size: 48px;
}

@keyframes spin {
  0% { transform: rotate(0deg);}
  100% { transform: rotate(360deg);}
}