html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 60px;
  /* Margin bottom by footer height */
  background: transparent;
}

html, body {
  height: 100%;
  margin: 0;
}

canvas {
  display: inline;
}

:root {
  --page-width: 100;
  --page-height: calc( var(--page-width) * 1.41 );
}

.container {
  padding-left: 0px !important;
}

.profile-icon {
  font-size: 26px;
  font-weight: bolder;
  color: #ffffff;
  cursor: pointer;
}

.video-choice-container {
  background-color: #a9a9a9;
  border-radius: 10px;
  color: #ffffff;
  font-weight: 700;
  padding: 10px 20px;
  cursor: pointer;

  margin: auto;
}

.video-current-choice-contaier {
  background-color: #F2DA64 !important;
}

.student-answer-container {
  max-width: 450px;
  width: 450px;
  background-color: #fff;
  color: #000000;
  padding: 10px;
}

.student-comment-container {
  max-width: 450px;
  width: 450px;
  background-color: #fff;
  color: #000000;
  padding: 10px;
  border: 1px solid black;
}

.page-station {
  border: 1px solid #c4c4c4;
}
 
.return-dashboard {
  cursor: pointer;
}

.reset-password-content {
  padding: 20px;
}

.invitation-title {
  font-family: 'Yellowtail', cursive;
}

.reset-content {
  width: 500px;
}

.game-title-mobile-container {
  max-width: 260px;
  width: 260px;
}

.no-select-btn {
  filter: grayscale(100%);
  cursor: default !important;
}

.game_canvas_background {
  background-color: #F0F7EB;
  height: 100vh;
  padding-bottom: 6.0rem;
}

.homepage-card-container {
  background-color: #ffffff;
}

.error-container {
  border: 1px solid #D2D2D2;
  border-radius: 10px;
  padding: 5px 0 5px;
  margin-left: 10px;
  margin-bottom: 10px;
  background-color: #ffffff;
}

.btn-nav {
  font-family: 'Alegreya', serif;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #f24406;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  margin: 0.5rem;
  padding: 0.25rem 3.0rem;
}

.error-notification {
  font-size: 10px;
  color: #db473b;
  font-weight: bolder;
}

.error-list {
  list-style-type: none;
  padding: 0;
}

.error-text {
  font-size: 14px;
  color: #db473b;
  font-weight: bolder;
}

.profile-top-header {
  background-color: #013F10 !important;
  width: 100%;
  padding: 25px 70px;
}

.classroom-card-body {
  flex: 1 1 auto;
  min-height: 1px;
}

.select-classroom-tab {
  color: #000000;
  border-bottom: 1px solid #D2D2D2; 
  cursor: pointer;
}

.select-classroom-tab-select {
  font-weight: bolder;
  border-bottom: 1px solid #000000;
}

.agreement-container {
  font-size: 14px;
  color: #a9a9a9;
}

.reset-password-info-user-container {
  line-height: 1rem;
  color: #a9a9a9;
}

.grade-headers {
  z-index: 5000;
}

.reset-password-info-container {
  width: 400px;
  line-height: 1rem;
  color: #a9a9a9;
}

.confirm-account-container {
  padding-left: 15% !important;
  line-height: 1rem;
  color: #a9a9a9;
}

.agreement-link {
  color: #a9a9a9;
  text-decoration: underline;
}

.div-classroom-styling {
  width: 1600px;
  min-height: 50vh;
  border: 1px solid transparent !important;
  margin-top: 20px;
  margin-bottom: 20px;
}

.fifth-grade-card {
  border: 5px solid #DB473B;
  color: #ffffff;
}

.grade-box-fifth {
  background-color: #DB473B;
}

.loading {
  animation: load infinite 5s linear;
  font-size: 40px;
  /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
}

.stream-container {
  position: relative;
  width: auto;
  left: 0;
  right: 0;
  padding: 15px 10px;
  background-color: #f5f5f5;
  border: 1px solid #D2D2D2;
  border-radius: 5px;
}

.student-container {
  width: 1000px;
  left: 0;
  right: 0;
  padding: 15px 10px;
  background-color: #f5f5f5;
  border: 1px solid #D2D2D2;
  border-radius: 5px;
}

.input-stream-text {
  max-width: 850px;
  width: 100%;
  height: auto;
  background-color: #f5f5f5;
  padding: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
}

.input-stream-form {
  -webkit-box-shadow: 5px 5px 6px -2px rgba(0,0,0,0.5); 
  box-shadow: 5px 5px 6px -2px rgba(0,0,0,0.5);
}

.input-stream-text:focus {
  outline: none;
}

.message-text-container {
  max-width: 780px;
  height: auto;
  background-color: #ffffff;
  border: 0.5px solid #D2D2D2;
  border-radius: 5px;
  padding: 10px;
}

.classroom-tab {
  color: #000000;
}

.classroom-btn {
  color: #000000;
  font-size: 16px;
  background-color: #f5f5f5;
  border: 0.5px solid #D2D2D2;
  border-radius: 10px;
  cursor: pointer;
  -webkit-box-shadow: 5px 5px 6px -2px rgba(0,0,0,0.5); 
  box-shadow: 5px 5px 6px -2px rgba(0,0,0,0.5);
}

.classroom-btn-add-student {
  color: #000000;
  font-size: 14px;
  padding: 0px 8px 0px 0px;
  background-color: #468C45;
  border-radius: 10px;
  cursor: pointer;
}

.classroom-btn:hover {
  text-decoration: none;
}

.classroom-btn:active {
  transform: scale(0.98);
  -webkit-box-shadow: 3px 3px 4px -2px rgba(0,0,0,0.5); 
  box-shadow: 3px 3px 4px -2px rgba(0,0,0,0.5);
}

.flashing {
  animation: flash infinite 3s ease-in-out alternate;
  -webkit-transition: flash 2000ms ease-in-out;
  -moz-transition:    flash 2000ms ease-in-out;
  -ms-transition:     flash 2000ms ease-in-out;
  -o-transition:      flash 2000ms ease-in-out;
  transition:         flash 2000ms ease-in-out;
  font-size: 40px;
  /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
}

@keyframes load {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes flash {
  from {
    transform: scale(1);
    filter: brightness(1);
  }
  to {
    transform: scale(1.2);
    filter: brightness(1.25);
  }
}

@keyframes flash {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.5);
  }
}

.div_classroom_card {
  position: relative;
  min-width: 250px;
  margin: 40px 20px;
  background-color: #ffffff;
}

.div-classroom-title-card {
  padding: 10px;
}

.btn-add-game:hover {
  text-decoration: none;
}

.classroom-card-dropdown {
  width: 60% !important;
  margin: 0 500px 0 66% !important;
}

.click-menu {
  color: #000000;
}

.stats-col {
  flex-basis: 0;
  max-width: 100%;
}

.classroom-row {
  display: flex;
  flex-wrap: wrap;
}
.classroom-link-text {
  font-weight: 900 !important;
  color: #ffffff;
}

.App {
  font-family: myriad-pro, sans-serif;
  font-style: normal;
  position: relative;
  min-height: 100vh;
  background-color: #F0F7EB;
  position: relative;
}

.grade-boxes-container {
  max-width: 1400px;
  width: 100%;
  padding-right: 15px;
  margin-right: auto;
  margin-left: auto;
}

.classroom-select {
  border: 2px solid #CCCCCB;
  border-radius: 5px;
  width: 746px;
  padding: 10px;
}

.spring-sign-in-header-theme {
  background-color: #123B02;
}

.spring-header-theme {
  background-color: #468C45;
}

.spring-homepage-theme {
  background-color: #C5D9B9;
}

.spring-nav-grade-theme {
  background-color: #F0F7EB;
}

.spring-footer-theme {
  background-color: #468C45;
}

.spring-footer-theme-text {
  color: #FA8754;
}

.answer-container {
  padding-left: 40px;
}

.student-container-unopened {
  background-color: #F0F7EB;
  color: #000000;
  padding: 7.5px 35px;
  margin: 10px 0;
  border-radius: 10px;
}

.btn-game-thumbnail {
  font-family: 'Alegreya', serif;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  color: #ffffff;
  background-color: #F19962;
  filter: brightness(105%);
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  border-radius: .25rem;
  margin: 0.5rem;
  padding: 0.25rem 3.0rem;
}

.btn-play-game {
  font-family: 'Alegreya', serif;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  color: #ffffff;
  background-color: #db473b;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 2.5rem;
  margin: 0.5rem;
  padding: 0.25rem 3.0rem;
}

.time-expire-text {
  font-weight: bolder;
  font-style: italic;
  color: #808080;
}

.btn-play-game:hover {
  background-color: #F19962;
  text-decoration: none;
  color: #ffffff;
}

.crossword-field-box:focus {
  outline: none !important;
  border: 1px solid yellow;
}

.btn-play-now-game {
  font-family: 'Alegreya', serif;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  color: #ffffff;
  background-color: #468C45;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 2.5rem;
  margin: 0.5rem;
  padding: 0.25rem 3.0rem;
}

.btn-not-available {
  font-family: 'Alegreya', serif;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  color: #ffffff;
  background-color: #666666;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 2.5rem;
  margin: 0.5rem;
  padding: 0.25rem 3.0rem;
}

.btn-play-now-game:hover {
  background-color: #013F10;
  text-decoration: none;
  color: #ffffff;
}

.profile-cursor {
  cursor: pointer;
}

#gameboard-container {
  min-height: 100%;
  height: auto;
  margin-bottom: -220px;
}

@keyframes updown {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(15%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes downup {
  0% {
    transform: translateY(15%);
  }

  50% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(15%);
  }
}

@keyframes updowntwo {
  0% {
    transform: translateY(0%);
  }

  40% {
    transform: translateY(15%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes rotateTilt {
  0% {
    transform: rotate(-30deg);
  }

  40% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-30deg);
  }
}



@keyframes leftright {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(15%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes rightleft {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(-15%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes rotateTiltTwo {
  0% {
    transform: rotate(0);
  }

  40% {
    transform: rotate(15deg);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes expand {
  0% {
    transform: scale(0);
  }

  50% {
    transform: rotate(2%);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes tilt {

}

.expanding-image {
  animation: expand 3s ease infinite;
}

.up-down-image {
  animation: updown 2s ease infinite;
}

.up-down-image-two {
  animation: updowntwo 4s ease infinite;
}

.down-up-image {
  animation: downup 3s ease infinite;
}

.left-right-image {
  animation: leftright 2s ease infinite;
}

.right-left-image {
  animation: rightleft 2s ease infinite;
}

.tilting-image {
  animation: rotateTilt 0.2s ease infinite;
}

.tilting-image-two {
  animation: rotateTiltTwo 4s ease infinite;
}

.finished-rocket-image {
  animation: finishRocket 4s ease;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

.rotating-image {
  animation: rotateFull 3s ease infinite;
}

@keyframes rotateFull {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes finishRocket {
  0% {
    transform: translateY(0%) translateX(0%) rotate(30deg)
  }

  10% {
    transform: translateY(-50px) translateX(-50px) rotate(0deg)
  }

  40% {
    transform: translateY(-120px) translateX(-100px) rotate(-15deg)
  }

  100% {
    transform: translateY(-800px) translateX(-200px) rotate(-5deg)
  }
}

.user-modal {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-width: 550px;
  min-height: 700px;
  margin: auto;
  z-index: 9999999;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
}

.badge-modal {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-width: 550px;
  min-height: 700px;
  margin: auto;
  z-index: 20000001;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
}

.closet-modal-content {
  position: relative;
  background-color: 343434;
  text-align: center;
  overflow: hidden;
  border-radius: 12.5px;
  min-height: 10vh;
  width: fit-content;
  height: fit-content;
  margin: auto;
  margin-top: 11.5%;
}

.gameboard-modal-content {
  position: relative;
  background-color: 343434;
  text-align: center;
  overflow: hidden;
  border-radius: 12.5px;
  min-height: 10vh;
  width: auto;
  height: auto;
  margin: auto;
}

.gameboard-modal-correct-content {
  position: relative;
  background-color: 343434;
  text-align: center;
  overflow: hidden;
  border-radius: 12.5px;
  min-height: 10vh;
  width: auto;
  height: auto;
  margin: auto;
  z-index: 40000;
}

.gameboard-badge-correct-content {
  position: relative;
  background-color: 343434;
  text-align: center;
  overflow: hidden;
  border-radius: 12.5px;
  min-height: 10vh;
  width: auto;
  height: auto;
  margin: auto;
  z-index: 99999;
}

.user-modal-content {
  position: relative;
  background-color: #F0F7EB;
  text-align: center;
  overflow: hidden;
  border-radius: 12.5px;
  min-height: 10vh;
  width: fit-content;
  height: fit-content;
  margin: auto;
  margin-top: 1.5%;
}

.holi-bliss-activity-two-content {
  position: absolute;
  background-color: transparent;
  text-align: center;
  overflow: hidden;
  border-radius: 12.5px;
  min-height: 10vh;
  width: fit-content;
  height: fit-content;
  margin: auto;
  margin-top: 11.5%;
  top: 25px;
  left: 450px;
}

.user-modal-content-draggable-dice {
  position: absolute;
  overflow-y: scroll;
  background-color: #D8F2FF;
  border: 1px solid #87CEEB;
  text-align: center;
  top: 100px;
  left: 100px;
  overflow: hidden;
  border-radius: 12.5px;
  min-height: 10vh;
  width: fit-content;
  height: fit-content;
  z-index: 5552100;
}

.user-modal-content-draggable {
  position: absolute;
  overflow-y: scroll;
  background-color: #F0F7EB;
  border: 1px solid #013F10;
  text-align: center;
  top: 100px;
  left: 100px;
  overflow: hidden;
  border-radius: 12.5px;
  min-height: 10vh;
  width: fit-content;
  height: fit-content;
  z-index: 5552100;
}

.access-content {
  padding: 0px 0px;
}

.sign-up-content {
  padding: 20px 30px;
}

.sub-desc-txt {
  font-size: 18px;
}

.select-avatar {
  border: 2px solid #F24406;
}

.stats-text {
  font-weight: bolder;
}

.close {
  cursor: pointer;
  color: #db473b !important;
}
.close:hover {
  color: #F19962;
}

.user-modal-header {
  color: #F24406;
  font-size: 28px;
  font-weight: bolder;
  text-decoration: underline;
}

.label-sign-in-text {
  color: darkgreen;
  font-weight: 600;
}

.forgot-password-text {
  color: #F19962;
}

.input-text {
  border: 2px solid #CCCCCB;
  border-radius: 5px;
  padding: 5px 10px;
}

.card-container {
  position: relative;
}

.more-content-text {
  color: #000000;
  font-weight: 300;
}

.message-container {
  height: 260px;
  display: absolute;
  overflow: auto;
}

.add-student-container {
  width: 220px;
  height: 420px;
  color: #000000;
  border: 2px solid #CCCCCB;
  border-radius: 10px;
  padding: 20px;
}

.add-entry-container {
  width: 220px;
  height: auto;
  background-color: #ffffff;
  color: #000000;
  border: 2px solid #CCCCCB;
  border-radius: 10px;
  padding: 20px;
}

.info-student-container {
  width: 746px;
  font-size: 14px;
  left: 0;
  right: 0;
  padding: 5px;
  background-color: transparent;
  border-bottom: 1px solid #D2D2D2;
}

.current-page {
  font-weight: bolder;
  color: #468C45;
}

.current-leaderboard-page {
  font-weight: bolder;
  color: #F2DA64;
}

.non-active-leaderboard-page {
  font-weight: bolder;
  color: #BCA12E;
}

.show-student-container {
  width: 746px;
  font-size: 14px;
  left: 0;
  right: 0;
  padding: 5px;
  background-color: #f5f5f5;
  border: 1px solid #D2D2D2;
  border-radius: 5px;
}

.classroom-btn-plus {
  font-size: 50px;
  padding: 0 10px;
}

.classroom-btn-plus-sm {
  font-size: 10px;
  padding: 0 10px;
}

.add-student-tab {
  width: 1050px;
} 

.inside-classroom-dropdown {
  width: 12% !important;
  margin: -40px 500px 0px 88% !important;
}

.message-dropdown {
  width: 12% !important;
  margin: -7% 500px 0px 85% !important;
}

.make-up-indicator {
  color: #DB473B;
}

.div_game_code_box_width {
  width: 200px !important;
}

.input-add {
  width: 150px;
  background-color: #F5F5F5;
  border: 1px solid transparent;
  border-radius: 5px;
}

.input-add-submit {
  background-color: #468C45 !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #E98129; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #E98129; 
}

.second-grade-card {
  border: 5px solid #0E78C6;
  color: #ffffff;
}

.grade-box-second {
  background-color: #0E78C6;
}

.third-grade-card {
  border: 5px solid #F2DA64;
  color: #ffffff;
}

.grade-box-third {
  background-color: #F2DA64;
}

.fourth-grade-card {
  border: 5px solid #E98129;
  color: #ffffff;
}

.grade-box-fourth {
  background-color: #E98129;
}

.fifth-grade-card {
  border: 5px solid #DB473B;
  color: #ffffff;
}

.grade-box-fifth {
  background-color: #DB473B;
}

.sixth-grade-card {
  border: 5px solid #AFD81B;
  color: #ffffff;
}

.grade-box-sixth {
  background-color: #AFD81B;
}

.seventh-grade-card {
  border: 5px solid #C18ED6;
  color: #ffffff;
}

.grade-box-seventh {
  background-color: #C18ED6;
}

.eighth-grade-card {
  border: 5px solid #6CC6E5;
  color: #ffffff;
}

.grade-box-eighth {
  background-color: #6CC6E5;
}

.high-school-card {
  border: 5px solid #E61587;
  color: #ffffff;
}

.grade-box-hs {
  background-color: #E61587;
}