.timer-text-box {
  font-weight: 400;
  border: 1px solid #000000;
  border-radius: 5px;
  padding: 0px 20px;
  background-color: #ffffff;
}

.start-btn {
  font-family: 'Alegreya', serif;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #AFD81B;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  margin: 0 0.25rem;
}

.start-btn:hover {
  background-color: #468C45;
}

.btn-clear {
  font-family: 'Alegreya', serif;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #f24406;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  margin: 0 0.25rem;
}

.pause-btn {
  font-family: 'Alegreya', serif;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #6CC6E5;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 0.5rem; 
  margin: 0 0.25rem;
}

.pause-btn:hover {
  background-color: #2B91BF;
}

.no-digit {
  color: #f5f5f5;
}

.digit {
  color: #000000;
}

@-webkit-animation borderBlink {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}

@keyframes borderBlink {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}

.digit-border {
  border-right: 1px solid #000000;
  -webkit-animation: borderBlink 1s step-end infinite;
  animation: borderBlink 1s step-end infinite;
}