.station-status-container {
    position: absolute;
    background-color: #DB473B;
    color: #ffffff;
    font-size: 12px;
    width: 18px;
    height: 18px;
    text-align: center;
    /*margin: 10px 1150px 10px 85.5%;*/
    margin: -40px 100px 0px 72px;
  
    border-radius: 100%;
    float: right;
  }

  .station-student-status-container {
    position: absolute;
    background-color: #DB473B;
    color: #ffffff;
    font-size: 12px;
    width: 18px;
    height: 18px;
    text-align: center;
    /*margin: 10px 1150px 10px 85.5%;*/
    margin: -25px 100px 0px 200px;
  
    border-radius: 100%;
    float: right;
  }

  
.station-name-container {
    background-color: #fff;
    color: #468C45;
    border-radius: 10px;
  }

.station-approve-container {
    background-color: #DB473B;
    font-size: 14px;
    text-align: center;
    margin: 10px;
    color: #fff;
}

.station-wip-container {
  background-color: #D2D2D2;
  font-size: 14px;
  text-align: center;
  margin: 10px;
  color: #fff;
}

.student-answer-text-area {
  background-color: #f5f5f5;
  border: transparent;
  padding: 5px;
}

.student-approve-container {
  background-color: #DB473B;
  width: 100px;
  height: 25px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 0 10px;
  color: #fff;
}

.student-wip-container {
  background-color: #D2D2D2;
  width: 100px;
  height: 25px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 0 10px;
  color: #fff;
}

.student-chevron-answer-container {
  background-color: #468C45;
  border: transparent;
  width: auto;
  font-size: 18px;
  font-weight: bold;
  padding: 5px 10px;
  text-align: center;
  color: #fff;
}

.student-chevron-wait-container {
  background-color: #F2DA64;
  border: transparent;
  width: auto;
  font-size: 18px;
  font-weight: bold;
  padding: 5px 10px;
  text-align: center;
  color: #fff;
  cursor: default !important;
}

.student-chevron-approve-container {
  background-color: #DB473B;
  border: transparent;
  width: auto;
  font-size: 18px;
  font-weight: bold;
  padding: 5px 10px;
  text-align: center;
  color: #fff;
  cursor: default !important;
}

.not-present {
  background-color: #f5f5f5 !important;
}

.wait-btn {
  background-color: #F2DA64;
}