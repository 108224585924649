.game-menu {
  color: #ffffff;
}

.game-activity-container {
  width: 322px;
  height: 330px;
  border-right: 2px solid #0E78C6;
  border-bottom: 2px solid #0E78C6;
  border-left: 2px solid #0E78C6;
  border-radius: 10px;
  margin: 20px;
}

.game-activity-header {
  background-color: #f5f5f5;
  border-top: 2px solid #0E78C6;
  border-bottom: 2px solid #0E78C6;
  border-radius: 10px 10px 0 0;
  padding: 10px;
}

.games-played-container {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.game-activity-grid {
  flex-wrap: wrap;
  column-gap: 0px;
}

.game-history-container {
  height: 140px;
  display: absolute;
  overflow: auto;
  scrollbar-width: thin;
}