@keyframes translateInf {
  100% { 
    transform: translateX(calc(-180px * 24));  
  }
}

.expanding-image {
  transform-origin: left top;
  animation: scale 2s ease-in-out alternate infinite;
}

.bright-star {
  animation: brighten 2s ease-in-out alternate infinite;
}

@keyframes brighten {
  from {
    filter: brightness(0.75);
  }
  to {
    filter: brightness(1);
  }
}

@keyframes scale {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.5);
  }
}


.infinite-slider-container {
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.infinite-slider-word {
  color: #343434;
  font-weight: 700;
  color: #fff;
}

.infinite-slider::before,
.infinite-slider::after {
  content: " ";
  z-index: 9;
}

.infinite-slider::before {
  top:0; left:0;
}

.infinite-slider::after {
  top:0; right:0;
}