.error-title {
  font-family: 'Alegreya', serif;
  font-size: 40px;
  font-weight: 400;
}

.homepage-button {
    font-family: 'Alegreya', serif;
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 700;
    color: #ffffff;
    background-color: #87ceeb;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    border-radius: 22px;
    margin: 4.0rem 2.5rem 2.5rem 2.5rem;
    padding: 0.25rem 3.0rem;
}

.homepage-button:hover {
  background-color: #00008b;
  text-decoration: none;
  color: #ffffff;
}

.error-background-container {
  background: #ffffff;
}