.jumbotron-top {
    padding: 1%;
    background-color: #C5D9B9;
}

.p_top_text_size {
    font-size: 36px;
}

.p_bottom_text_size {
    font-size: 22px;
}