.bottom-header {
  background-color: #468C45;
  height: 80px;
}

.nav-bgcolor {
  background-color: #468C45;
}

.nav-logo {
  width: 300px;
}

.btn-bgcolor-search {
  background-color: #F24406 !important;
  color: white !important;
}

.div_searchbox_width {
  width: 400px !important;
}

.div_searchbox_tablet_width {
  width: 250px !important;
}

.div_searchbox_tablet_width_2 {
  width: 200px !important;
}

.mobile-menu {
  color: #ffffff;
}
