
.dice {
    height: 175px;
    width: 150px;
    background-color: #EEDC82;
    border: 3px solid #000000;
    border-radius: 10px;
    margin: 15px 20px;
  }
  
  .dice-checkbox-container {
    display: flex;
    flex-direction: row;
    gap: 25px;
  }
  
  .dice-checkbox {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .dice-box {
    padding-right: 20px;
  }
  
  .dot {
    border-radius: 100%;
    background-color: #000000;
    width: 25px;
    height: 25px;
  }
  
  .dot-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 45%;
  }
  
  .dot-container-1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 45%;
  }
  
  .dot-container-2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 45%;
  }
  
  .dot-container-3 {
    display: grid;
    grid-template-columns: 26px 26px 26px;
    grid-template-rows: 26px 26px 26px;
    grid-auto-flow: column;
    gap: 25px;
    margin-top: 15%;
    margin-left: 5%;
  }
  
  .item-3-1 {
    grid-column: 3 / 3;
    grid-row: 1 / 2;
  }
  
  .item-3-2 {
    grid-column: 2 / 2;
    grid-row: 2 / 2;
  }
  
  .item-3-3 {
    grid-column: 1 / 2;
    grid-row: 3 / 3;
  }
  
  .dot-container-4 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 24%;
  }
  
  .dot-container-5 {
    display: grid;
    grid-template-columns: 26px 26px 26px;
    grid-template-rows: 26px 26px 26px;
    grid-auto-flow: column;
    gap: 25px;
    margin-top: 14%;
    margin-left: 6.5%;
  }
  
  .item-5-1 {
    grid-column: 1 / 1;
    grid-row: 1 / 2;
  }
  
  .item-5-2 {
    grid-column: 3 / 3;
    grid-row: 1 / 2;
  }
  
  .item-5-3 {
    grid-column: 2 / 2;
    grid-row: 2 / 2;
  }
  
  .item-5-4 {
    grid-column: 1 / 2;
    grid-row: 3 / 3;
  }
  
  .item-5-5 {
    grid-column: 3 / 3;
    grid-row: 3 / 3;
  }
  
  .dot-container-6 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 38px;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 7%;
  }
  