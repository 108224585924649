.div_top_header {
  background-color: #013F10 !important;
  width: 100%;
  height: 50px;
  color: #FFFFFF;
  
}

.div_container_style {
  padding-top: 10px;
  padding-bottom: 10px;
}

.text-game-log-in {
  font-family: 'Alegreya', serif;
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 400;
  color: #000000;
  filter: brightness(105%);
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  border-radius: .25rem;
  
}

.text-game-log-in:hover {
  color: #000000;
  text-decoration: underline;
}

.text-classroom-log-in {
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 400;
  color: #ffffff;
  filter: brightness(105%);
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.text-classroom-log-in:hover {
  text-decoration: underline;
}

.btn-game-log-in {
  background-color: #F19962;
  font-family: 'Alegreya', serif;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 700;
  color: #ffffff;
  filter: brightness(105%);
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  border-radius: .25rem;
  margin: 0.2rem 0.0rem 0 1.5rem;
  padding: 0rem 0.5rem 0 1.0rem;
}

.btn-game-log-in:hover {
  color: #ffffff;
  text-decoration: none;
}

.btn-teacher-log-in {
  background-color: transparent;
  font-family: 'Alegreya', serif;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 700;
  color: #ffffff;
  filter: brightness(105%);
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  border-radius: .25rem;
  margin: 0.2rem 0.0rem 0 1.5rem;
  padding: 0rem 0.5rem 0 1.0rem;
}

.div_teacherpage_top_header {
  background-color: #013F10;
  height: 60px;
  color: #FFFFFF;
}

.div_teacherpage_topheader_container_style {
  padding-top: 10px;
  padding-bottom: 10px;
}

.div_teacherpage_styling {
  background-color: #F19962;
  height: 35px;
  font-weight: bolder;
  vertical-align: middle;
  font-size: 13px;
  width: 400px;
  line-height: 35px;
  cursor: pointer;
}


.user-dropdown {
  position: absolute !important;
  flex-wrap: wrap;
  margin: 5px 0;
  border: 1px solid #D2D2D2;
  border-radius: 8px;
  background-color: #ffffff;
  height: auto;
  font-weight: bold;
  font-size: 13px;
  width: 600px;
  line-height: 28px;
  cursor: pointer;
  z-index: 3000;
}

.menu-user-dropdown {
  position: relative;
  width: 180px !important;
}

.text-profile-log-in {
  color: #ffffff !important;
}