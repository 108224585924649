.footer {
    bottom: 0;
    width: 100%;
    height: auto;
    /* Set the fixed height of the footer here */
    /* line-height: 20px; */
    /* Vertically center the text there */
    background-color: #468C45;
    color: #F19962;
    float: left;
    overflow: hidden;
}

@media only screen and (max-width: 412px) {
  .footer {
    margin-top: 10px;
    position: block;
  }
}

@media only screen and (min-width: 332px) {
  .footer {
    position: absolute;
  }
}

.spn_font {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bolder;
}

.a_bottom_font_style {
    font-size: 12px;
    font-weight: bold;
    color: #F19962;
}

.a_bottom_font_style:hover {
    color: #C5D9B9;
    text-decoration: none;
}

.div_bottom_font_style {
    font-family: myriad-pro-semiextended, sans-serif;
    font-weight: 300;   
    font-style: normal;
    padding: 10px;
    font-size: 12px;
}