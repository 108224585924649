.div_game_card {
  max-height: 300px;
  min-height: 250px;
  margin: 40px 20px;
  border: 1px solid #D2D2D2 !important;
  cursor: pointer;
}

.div_game_card:hover {
  filter: brightness(95%);
}

.col-game-container {
  flex-basis: 0;
  max-width: 100%;
}

.div_first_box {
  background-size: cover;
  background-repeat: no-repeat;
  padding: 90px;
}
  
.div_second_box {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 40px;
}
  
.div_game_title_text {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  padding: 0.25rem !important;
}

.game_container_background {
  background-color: #F0F7EB;
  height: auto;
}


.game-title-text {
  font-family: 'Alegreya', serif;
  font-size: 30px;
}

.game-container {
  border-bottom: 2px solid #D2D2D2;
  overflow: visible;
}

.game-text {
  color: #000000;
}