.sort-by-content::before {
    content: 'Sort by: ';
}

.tool-grid {
  flex-wrap: wrap;
  column-gap: 90px;
  row-gap: 0px;
}

option:hover {
    background-color: darkgreen !important;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  text-decoration: none;
  color: #ffffff;
  background-color: darkgreen;
}

.game-type-container {
  position: absolute;
  color: #ffffff;
  font-size: 12px;
  width: auto;
  height: auto;
  padding: 5px 15px;
  /*margin: 10px 1150px 10px 85.5%;*/
  top: 5%;
  right: -15px;

  border-radius: 100px 0 0 100px;
  float: right;
}

.div_all_grades_container {
  background-color: #000000;
}