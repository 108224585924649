.gameboard {
  position: relative;
  background-position: contain;
  background-size: cover;
  background-repeat: no-repeat;
  aspect-ratio: 16 / 9;
}

.responsive-gameboard {
  position: relative;
  margin: 0 auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  aspect-ratio: 3 / 2;
}

.leaderboard-select {
  z-index: 3002;
}

.leaderboard-entry {
  background-color: #96302A;
  color: #000000;
  width: auto;
  height: auto;
}

.transform-component-module_wrapper__1_Fgj {
  margin: 0 auto !important;
}

.leaderboard-entry-container {
  background-color: #F2DA64;
}

.leaderboard-header {
  background-color: #BCA12E;
  border-radius: 10px;
  margin: 10px 0 -10px 0;
  padding: 10px 0;
  z-index: 500;
}

.pathway-selected {
  border: 2px solid #78ff39;
  border-radius: 10px;
}

.drag-and-drop-container {
  position: absolute;
  color: #ffffff;
  background: rgb(255,0,198);
  background: linear-gradient(90deg, rgba(255,0,198,1) 73%, rgba(74,12,67,1) 200%);
  width: auto;
  min-width: 100px;
  height: 24px;
  border-radius: 20px;
}

.leaderboard-title {
  color: #ffffff
}

.leaderboard-header-container {
  margin: -18px 0 10px 0;
}

.marker-gameboard-list {
  list-style-type: none;
  padding-left: 0;
}